.title {
  font-size: 28px;
  font-weight: 700;
  color: #39658e;
  margin: 0;
  margin-bottom: 20px !important;
}
.subtitle {
  font-size: 20px;
  font-weight: 700;
  color: #39658e;
  margin: 0;
}
.subtitle:not(:first-child) {
  margin-top: 20px;
}
