@color-background: #deeaf5;
@color-accent: #39658e;
@color-font: #222222;
@color-font-menu: #cad7e2;
@color-font-menu-highlight: #f6f8fa;
@size-border: 5px;
@size-distance: 10px;
@mobile-threshold: 600px;
@desktop-threshold: 800px;
@size-maxwidth: 1000px;
@size-border-distance: 30px;
@size-content-distance: 60px;

.font-title() {
  font-size: 28px;
  font-weight: 700;
}

.font-subtitle() {
  font-size: 20px;
  font-weight: 700;
}
