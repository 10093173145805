@import (reference) "./styles.less";

.title {
  .font-title;
  color: @color-accent;
  margin: 0;
  margin-bottom: @size-distance * 2 !important;
}

.subtitle {
  .font-subtitle;
  color: @color-accent;
  margin: 0;

  &:not(:first-child) {
    margin-top: @size-distance * 2;
  }
}
