@font-face {
  font-family: Icons;
  src: url("./icons.woff");
}
body {
  margin: 0;
  color: #222222;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #deeaf5;
}
@media (min-width: 800px) {
  body {
    display: flex;
    justify-content: center;
  }
}
p {
  margin: 0;
}
a {
  color: #39658e;
}
li {
  margin: 0;
}
@media (min-width: 600px) {
  .layout {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 1000px;
  }
  .border {
    position: absolute;
    top: 30px;
    left: 30px;
    bottom: 30px;
    right: 30px;
    border: solid #39658e 5px;
  }
  .menu {
    background-color: rgba(57, 101, 142, 0.8);
    position: absolute;
    width: 220px;
    top: -50px;
    bottom: -50px;
    left: 0;
    border: solid 5px rgba(255, 255, 255, 0.7);
    border-top: none;
    border-bottom: none;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    padding: 70px 0;
  }
  .top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .top > div {
    height: 34px;
    width: 100%;
    max-width: 1000px;
    position: relative;
  }
  .top > div > div {
    position: absolute;
    background-color: #deeaf5;
    top: 0;
    bottom: 0;
    left: 320px;
    right: 30px;
    border-bottom: solid #39658e 4px;
  }
  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .bottom > div {
    height: 34px;
    width: 100%;
    max-width: 1000px;
    position: relative;
  }
  .bottom > div > div {
    position: absolute;
    background-color: #deeaf5;
    top: 0;
    bottom: 0;
    left: 320px;
    right: 30px;
    border-top: solid #39658e 4px;
  }
}
@media (min-width: 800px) {
  .menu {
    left: 50px;
    padding: 110px 0;
  }
}
@media (max-width: 800px) {
  .border,
  .top,
  .bottom {
    display: none;
  }
}
@media (max-width: 600px) {
  .menu {
    background-color: #39658e;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 0;
  }
}
.menu {
  text-align: center;
  color: #cad7e2;
}
.menu-title {
  font-size: 28px;
  font-weight: 700;
  color: #f6f8fa;
  margin: 0;
  margin-bottom: 20px;
}
.menu-item > a {
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}
.menu-item.active > a {
  color: #f6f8fa;
}
.menu-item:not(:last-child) {
  margin-bottom: 10px;
}
.content > :not(:last-child) {
  margin-bottom: 10px;
}
@media (min-width: 600px) {
  .content {
    margin: 20px 20px 20px 250px;
    position: relative;
  }
}
@media (min-width: 800px) {
  .content {
    margin: 60px 60px 60px 330px;
    box-sizing: border-box;
    max-width: 610px;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .content {
    margin: 20px;
  }
}
