.icontable {
  border-spacing: 0;
}
.icontable td {
  padding: 0;
}
.icontable td:not(:last-child) {
  padding-right: 10px;
}
.icontable tr:not(:last-child) td {
  padding-bottom: 10px;
}
.icon {
  font-family: Icons;
  vertical-align: top;
  color: #39658e;
}
