@import (reference) "./styles.less";

@media (min-width: @mobile-threshold) {
  .employee {
    display: flex;

    > img {
      margin-right: @size-distance;
      max-width: 20vw;
    }
  }
}

@media (max-width: @mobile-threshold) {
  .employee {
    > img {
      margin-bottom: @size-distance;
    }
  }
}
