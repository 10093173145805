@import (reference) "./styles.less";

.icontable {
  border-spacing: 0;

  td {
    padding: 0;

    &:not(:last-child) {
      padding-right: @size-distance;
    }
  }

  tr:not(:last-child) {
    td {
      padding-bottom: @size-distance;
    }
  }
}

.icon {
  font-family: Icons;
  vertical-align: top;
  color: @color-accent;
}
