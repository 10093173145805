@media (min-width: 600px) {
  .employee {
    display: flex;
  }
  .employee > img {
    margin-right: 10px;
    max-width: 20vw;
  }
}
@media (max-width: 600px) {
  .employee > img {
    margin-bottom: 10px;
  }
}
