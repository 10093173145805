@import (reference) "./styles.less";

@font-face {
  font-family: Icons;
  src: url("./icons.woff");
}

body {
  margin: 0;
  color: @color-font;
  font-family: Arial, Helvetica, sans-serif;
  background-color: @color-background;
}

@media (min-width: @desktop-threshold) {
  body {
    display: flex;
    justify-content: center;
  }
}

p {
  margin: 0;
}

a {
  color: @color-accent;
}

li {
  margin: 0;
}

@media (min-width: @mobile-threshold) {
  .layout {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: @size-maxwidth;
  }

  .border {
    position: absolute;
    top: @size-border-distance;
    left: @size-border-distance;
    bottom: @size-border-distance;
    right: @size-border-distance;
    border: solid @color-accent 5px;
  }

  .menu {
    background-color: fade(@color-accent, 80%);
    position: absolute;
    width: 220px;
    top: -50px;
    bottom: -50px;
    left: 0;
    border: solid @size-border fade(white, 70%);
    border-top: none;
    border-bottom: none;
    box-shadow: 0 0 20px fade(black, 50%);
    padding: 70px 0;
  }

  .top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    > div {
      height: @size-border-distance + (@size-border - 1px);
      width: 100%;
      max-width: @size-maxwidth;
      position: relative;

      > div {
        position: absolute;
        background-color: @color-background;
        top: 0;
        bottom: 0;
        left: 320px;
        right: @size-border-distance;
        border-bottom: solid @color-accent (@size-border - 1px);
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    > div {
      height: @size-border-distance + (@size-border - 1px);
      width: 100%;
      max-width: @size-maxwidth;
      position: relative;

      > div {
        position: absolute;
        background-color: @color-background;
        top: 0;
        bottom: 0;
        left: 320px;
        right: @size-border-distance;
        border-top: solid @color-accent (@size-border - 1px);
      }
    }
  }
}

@media (min-width: @desktop-threshold) {
  .menu {
    left: 50px;
    padding: (50px + @size-content-distance) 0;
  }
}

@media (max-width: @desktop-threshold) {
  .border,
  .top,
  .bottom {
    display: none;
  }
}

@media (max-width: @mobile-threshold) {
  .menu {
    background-color: @color-accent;
    width: 100%;
    padding-top: @size-distance;
    padding-bottom: @size-distance;
    top: 0;
  }
}

.menu {
  text-align: center;
  color: @color-font-menu;
}

.menu-title {
  .font-title;
  color: @color-font-menu-highlight;
  margin: 0;
  margin-bottom: @size-distance * 2;
}

.menu-item {
  > a {
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
  }

  &.active {
    > a {
      color: @color-font-menu-highlight;
    }
  }

  &:not(:last-child) {
    margin-bottom: @size-distance;
  }
}

.content {
  > :not(:last-child) {
    margin-bottom: @size-distance;
  }
}

@media (min-width: @mobile-threshold) {
  .content {
    margin: 20px 20px 20px 250px;
    position: relative;
  }
}

@media (min-width: @desktop-threshold) {
  .content {
    margin: @size-content-distance @size-content-distance @size-content-distance
      330px;
    box-sizing: border-box;
    max-width: @size-maxwidth - @size-content-distance - 330px;
    width: 100%;
  }
}

@media (max-width: @mobile-threshold) {
  .content {
    margin: 20px;
  }
}
